"use client";

import React, { useEffect, useRef } from "react";
import Lottie from "lottie-react";
import styled from "styled-components";

import PlWIDALoading from "./PlWIDALoading.json";

const LOTTIE_SPEED = 2;
const LOTTIE_WIDTH = "600px";

const Loading = () => {
  const lottieRef = useRef(null);

  useEffect(() => {
    if (lottieRef.current) {
      lottieRef.current.setSpeed(LOTTIE_SPEED);
    }
  }, []);

  return (
    <S.LoadingContainer>
      <S.LoaderWrapper>
        <S.LottieContainer>
          <Lottie
            lottieRef={lottieRef}
            animationData={PlWIDALoading}
            loop={true}
            autoplay={true}
          />
        </S.LottieContainer>
      </S.LoaderWrapper>
    </S.LoadingContainer>
  );
};

const S = {
  LoadingContainer: styled.div`
    opacity: 1;
    transition: 1s all;
    background: white;
    z-index: 100;
    overflow: hidden;
    position: fixed;
    width: 100vw;
    height: 100vh;
  `,
  LoaderWrapper: styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    text-align: center;
  `,
  LottieContainer: styled.div`
    width: ${LOTTIE_WIDTH};
  `,
};

export default Loading;
